footer {
  padding: $space-small;
  @media (min-width: $small) {
    padding: 45px;
  }
  @media (min-width: $medium) {
    padding: $space-medium;
  }
  padding-top: 40px !important;
  @media (min-width: $medium) {
    padding-top: 100px !important;
  }
  padding-bottom: 100px;
  &.haveVotes {
    padding-bottom: 200px;
  }
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
  }
  a:hover {
    color: $gold;
  }
  background-color: $purple2;

  @media (max-width: $max-small) {
    flex-direction: column !important;
    align-items: center !important;
  }

  @media (max-width: $max-small) {
    .footerLeft {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .footerCopyright {
        flex: 1;
        align-self: center;
        text-align: right;
      }
    }
    .footerRight {
      margin-top: $space-medium;
      text-align: center;
      align-items: center;
      .LanguageSelectorButton {
        margin-right: 10px;
      }
    }
  }

  .footerLogo {
    margin-bottom: 15px;
  }

  .footerCopyright {
    @extend .smallText;
    font-weight: bold;
  }

  .footerLanguages {
    margin-bottom: 15px;
  }

  .socialLinks {
    margin-bottom: 30px;
    a {
      color: #fff;
      opacity: 0.8;
      text-decoration: none;
      margin-right: 10px;
      @media (min-width: $small) {
        font-size: 24px;
        margin-right: 25px;
      }
      @media (min-width: $medium) {
        font-size: 24px;
        margin-left: 30px;
      }
      @media (min-width: $large) {
        font-size: 32px;
        margin-left: 50px;
      }
    }
    a:hover {
      opacity: 1;
    }
  }

  .pageLinks {
    @extend .smallText;
    align-items: center;
    a {
      opacity: 0.8;
      color: #fff;
      text-decoration: none;
      margin-right: 10px;
      @media (min-width: $small) {
        margin-right: 10px;
      }
      @media (min-width: $medium) {
        margin-left: 30px;
      }
      @media (min-width: $large) {
        margin-left: 50px;
      }
    }
    a:hover {
      opacity: 1;
    }
  }
}
