// Used for spacing content around the site

.contentWrapper {
  margin-top: 0;
}

// Width
.contentFullWidth {

}

.contentStandardWidth {
  margin-left: $space-medium;
  margin-right: $space-medium;
}

.contentNarrowWidth {
  margin: 0 auto;
  @media (max-width: $max-small) {
    margin-left: $space-medium;
    margin-right: $space-medium;
  }
  @media (min-width: $medium) {
    max-width: 70% !important;
  }
}

// alignment

.contentAlignCenter {
  text-align: center;
}
