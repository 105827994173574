.socialShare {
  color: $purple;
  text-align:center;
  margin-bottom: $space-medium;
  span {
    font-weight: bold;
    vertical-align: super;
  }
  .icon {
    font-size: 32px;
    color: $purple;
    margin-left: 10px;
  }
  .fb {
    color: #4267B2;
  }
  .tw {
    color: #1DA1F2;
  }
  .ig {
    color: #C13584;
  }
}

#voteCart {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 13;
  h3 {
    color: $purple2;
    span {
      color: $purple;
    }
    z-index: 2;

    @include font-abril;
    text-align: center;
    font-size: 18px;
    line-height: 24px;
    @media (min-width: $medium) {
      font-size: 32px;
      line-height: 49px;
    }
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .cartButtonContainer {
    z-index: 2;
  }
  .cartButton {
    width: 200px !important;
  }
  .cartButtonLeft {
    margin-right: 15px;
  }

  .modalClose {
      font-size: 32px;
  }

  .modalClose:hover {
    //color: #fff;
  }

  #voteIndicators {
    display: none;
  }

    @media (min-width: $medium) {
      #voteIndicators {
        display: flex;
        z-index: 2;
        margin-left: 10px;
        margin-bottom: 15px;
        .voteIndicator {
          &:hover {
            cursor: pointer;
          }
          transition: background 1s;
          margin-right: 10px;
          width: 32px;
          height: 32px;
          border-radius: 18px;
          border: 2px solid $purple;
          background-color: transparent;
          font-size: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .voteIndicatorYes {
          background: $purple;
          transition: background 1s;
        }
      }
    }



}

// Hover tips on vote indicators
.productTip {
  z-index: 2001;
  position: relative;
    background-color: $yellow;
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    text-align: center;
    margin-top: 5px;
    img {
      width: 80px;
    }
}

// Hover tips on vote indicators
.voteTip {
    background-color: $purple;
    padding: 5px;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 5px;
    img {
      width: 80px;
    }
}

#voteCartBackground {
  background-color: white;
}

#voteCartInner {
  @media (min-height: 500px) {
    padding: 12px;
  }
}

#voteCartStatus {
  background: $yellow;
  background: $gradient-gold-horizontal;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

// Toggled Cart
.voteCartToggled {
  overflow-y: auto;
}

// Minimised cart
// Show a minimsed version of the vote cart
.voteCartMinimised {
  #voteCartBackground {
  }
  #voteCartInner {
    flex-direction: row !important;
  }
  h3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  #voteIndicators {
    //display: none;
    //margin-top: 15px;
    margin-bottom: 0 !important;
  }
  .cartButtonLeft {
    //margin-right: 0px;
    //margin-left: 15px;
  }
  .cartButtonRight {
    margin-right: 15px;
  }
}

// Last chance bit at top of expanded voteCart
.lastChance {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: $purple;
  background: $gradient-purple-horizontal;
  padding: $space-medium;
  padding-bottom: 0;
  height: calc(100% - #{$space-medium});
  h3 {
    color: #fff !important;
  }
  .ballot {
    margin-top: $space-medium;
    margin-bottom: $space-medium;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    @media (min-width: $medium) {
      grid-template-columns: repeat(5, 1fr);
    }
    gap: $space-medium;
  }
  .ballotItem {
    width: 100%;
  }
}

// Last chance stuff
.cartProduct {
  width: 100%;
  min-height: 100px;
  @media (min-width: 300px) { height: 140px }
  @media (min-width: 400px) { height: 160px }
  @media (min-width: 500px) { height: 160px }
  @media (min-width: 600px) { height: 160px }
  @media (min-width: 700px) { height: 160px }
  @media (min-width: 800px) { height: 140px }
  @media (min-width: 900px) { height: 160px }
  @media (min-width: 1000px) { height: 180px }
  @media (min-width: 1100px) { height: 200px }
  @media (min-width: 1200px) {
    height: 100%;
    aspect-ratio: 1/1;
  }
  // wide but low height (aka mobile landscape)

  .cartProductBox {
    position: relative;
    width: 100%;
    height: 100%;
    //aspect-ratio: 1/1;
    border: 2px dashed $peach;
    align-items: center;
    overflow: hidden;
  }
  &.hasVote .cartProductBox  {
    border: 2px solid $peach;
    //aspect-ratio: 1/1;
  }
  .titleBar {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  .categoryImage {
    position: absolute;
    top: 0px;
    left: 10px;
    width: 50%;
  }
  h5 {
    color: #fff;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
  }
  font-size: 14px;
  line-height: 16px;
  .cartProductVote {
    background: transparent;
    border: 2px solid $peach;
    color: $peach;
    padding: 4px 8px;
    border-radius: 10px;
    @media (min-width: $medium) {
      padding: 5px;
      width: 100%;
      font-size: 12px;
      line-height: 16px;
    }

  }
  .modalClose {
    color: $peach;
    top: 5px;
    right: 0px;
    @media (min-width: $medium) {
      top: 10px;
      right: 10px;
    }
    font-size: 18px !important;
  }
  .modalClose:hover {
    color: #fff;
  }
}

.submissionError {
  color: #000;
}

// Form
#voteForm {
  margin: 0;
  min-height: 400px;
  input {
    color: #000;
  }
  label {
    color: #000;
  }
  .modalClose:hover {
    color: $purple;
  }
  .cog {
    color: $purple;
    font-size: 32px;
  }
}

#formErrors {
  font-size: 12px;
  p {

  }
}
