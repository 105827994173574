// Fade In
.transitionFadeIn {
    transition: 2s;
    opacity: 0;
    animation: fadeIn 2s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
}

.transitionFadeInFast {
    transition: 0.8s;
    opacity: 0;
    animation: fadeIn 0.8s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// Fade In then scale
.transitionFadeInScale {
    transition: 6s;
    opacity: 0;
    animation: fadeInScale 6s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
}

@keyframes fadeInScale {
  0% {
    opacity: 0;
    transform: scale(1);
  }
  15% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: scale(1.1);
  }
}

// Slide Up
.transitionSlideUp {
    transition: 1s;
    opacity: 1;
    animation: slideUp 1s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
    transform: translate3d(0, 8vh, 0);
}
.transitionSlideUpSlower { // Slightly slower
    transition: 1.5s;
    opacity: 1;
    animation: slideUp 1.5s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
    transform: translate3d(0, 8vh, 0);
}

@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 8vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Slide Down
.transitionSlideDown {
    transition: 1s;
    opacity: 1;
    animation: slideDown 1s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
    transform: translate3d(0, 8vh, 0);
}
.transitionSlideDownSlower { // Slightly slower
    transition: 1.5s;
    opacity: 1;
    animation: slideDown 1.5s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
    transform: translate3d(0, 8vh, 0);
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translate3d(0, -8vh, 0);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

// Transition delays
.delay-0 { transition-delay: 0s !important }
.delay-1 { transition-delay: 0.1s !important }
.delay-2 { transition-delay: 0.2s !important }
.delay-3 { transition-delay: 0.3s !important }
.delay-4 { transition-delay: 0.4s !important }
.delay-5 { transition-delay: 0.5s !important }
.delay-6 { transition-delay: 0.6s !important }
.delay-7 { transition-delay: 0.7s !important }
.delay-8 { transition-delay: 0.8s !important }
.delay-9 { transition-delay: 0.9s !important }
.delay-10 { transition-delay: 1s !important }

// Fade in Section
.fade-in-section {
  opacity: 0;
  transform: translateY(8vh);
  visibility: hidden;
  transition: opacity 0.6s cubic-bezier(0.07, 0.4, 0.185, 1), transform 1s cubic-bezier(0.07, 0.4, 0.185, 1);
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

// Pulse animations
.pulse-purple {
  transform: scale(1);
  animation: pulse-purple-keyframes 2s infinite;
}
@keyframes pulse-purple-keyframes {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($purple, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba($purple, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba($purple, 0);
  }
}
