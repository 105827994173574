button:focus {
  box-shadow: none !important; // Remove Grommet focus outline/shadow
}

.baseButton {
  padding: 10px 20px;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-weight: 500;
  color: #140d40;
  border-width: 0;
  background: linear-gradient(90deg, rgba(208,145,76,1) 0%, rgba(228,177,74,1) 50%, rgba(247,211,76,1) 100%);
}

.buttonGold {
  //@extend .baseButton;
  color: $blueDark;
  background: $gold;
  background: $gradient-gold-horizontal;
}

.buttonPurple {
  //@extend .baseButton;
  color: #fff !important;
  background: $purple2 !important;
  background: $gradient-purple2-horizontal !important;
}
