.countdown {
  width: 80%;
  max-width: 750px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $max-small){
    padding-left: 10%;
    padding-right: 10%;
  }
  h3 {
    @extend .bigText;
    font-size: 24px;
    margin-bottom: $space-medium;
  }
  .clock {
    width: 80%;
    .segments {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .segment {
      text-align: center;
      .number {
        @include font-abril;
        text-align: center;
        font-size: 48px;
        line-height: 60px;
        @media (min-width: $large) {
          font-size: 84px;
          line-height: 100px;
        }
      }
      .label {
        @include font-oswald-regular;
      }
    }
    .segmentSeconds {
      width: 60px;
      @media (min-width: $large) {
        width: 117px; // Give seconds a fixed width so it doesn't move around
      }
    }
  }
}
