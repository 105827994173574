.spacer-small {
  height: $space-small;
}
.spacer-medium {
  height: $space-medium;
}
.spacer-large {
  height: $space-large;
}
.spacer-huge {
  height: $space-xlarge;
  @media (min-width: $small) {
    height: $space-xxlarge;
  }
}
