.popup {
  position: relative;
  background: #fff;
  width: 90vw;
  @media (min-width: $medium) {
    width: 60vw;
  }
  margin-top: 30px;
  margin-bottom: 30px;
  @media (min-width: $medium) {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
  .header {
    background: #000;
    padding: 30px;
    text-align: center;
    .close {
      color: $gold;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
    }
    .close:hover {
      color: #fff;
      cursor: pointer;
    }
    h3 {
      text-align: center;
      color: #fff;
      @include font-abril;
      font-size: 36px;
      font-weight: 300;
      line-height: 48px;
      width: 100%;
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 0;
      margin-top: 0;
    }
    .categoryImage {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    .inducteeImage {
      margin: 20px auto;
      max-width: 400px;
    }
  }

  p.shortDescription {
    text-align: center;
    @include font-oswald-regular;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
    @media (min-width: $medium) {
        font-size: 24px;
        line-height: 32px;
    }
    color: $purple2;
  }
  .longDescription {
    span {
      line-height: 24px;
    }
  }
  .specs {
    margin-top: 0px;
    margin-bottom: 20px;
    @media (min-width: $medium) {
        margin-top: 10px;
        margin-bottom: 40px;
    }
  }
  .spec {
    text-align: center;
    h4 {
      margin: 0;
      color: $purple3;
      @include font-oswald-regular;
      font-size: 14px;
      @media (min-width: $medium) {
        font-size: 16px;
      }
    }
    .specValue {
      margin: 0;
      color: $purple2;
      @include font-oswald-medium;
      font-size: 16px;
      @media (min-width: $medium) {
        font-size: 22px;
      }
    }
  }

  .body {
    padding: 30px;
  }

  .footer {
    background: $purple2;
    background: $gradient-purple-horizontal;
  }
}
