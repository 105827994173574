.hero {
  position: relative;
  height: 90vh;
  @media (min-width: $medium) {
    height: 75vh;
  }
  @media (min-width: $large) {
    height: 75vh;
  }
  width: 100%;
  overflow: hidden;
  background-color: $black-almost !important;
  display: flex;
  align-items: center; // does vertically center the desired content
  justify-content: center; // horizontally centers single line items
  h1 {
    //position: absolute;
    margin: 0;
    //margin-top: 15%;
    flex: 0 0;
    color: #fff;
    @include font-abril;
    text-align: center;
    font-size: 48px;
    line-height: 60px;
    @media (min-width: $large) {
      font-size: 84px;
      line-height: 100px;
    }
    width: 100%;
    z-index: 12;
  }
  h1.loading {
    font-size: 24px;
    line-height: 32px;
    opacity: 0.5;
  }

  .heroImage {
    position: absolute;
    width: auto;
    height: 100%;
    @media (min-width: $medium) {
      width: 100%;
      height: auto;
    }
  }

  .parrallaxContainer {
    position: absolute;
    width: 100%;
    height: 100%;

    .heroImageDesktopParrallaxTop {
      z-index: 2;
      @media (max-width: $medium) {
        object-fit: cover;
      }
    }
    .heroImageDesktopParrallaxBottom {
      z-index: 1;
    }
  }
}

.heroDefault {
  //height: 50vh;
  height: 90vh;
  @media (min-width: $medium) {
    height: 75vh;
  }
  @media (min-width: $large) {
    height: 75vh;
  }
}

// Hero images
.heroImageLoading {
  opacity: 0;
}
.heroImageMobile {
  width: 100% !important;
  height: 100%;
  @media (min-width: $medium) {
    display: none;
  }
}
.heroImageDesktop {
  display: none;
  @media (min-width: $medium) {
    display: block;
  }
}
