.newsletter {
  padding: $space-xlarge 40px !important;
  @media (min-width: $small) {
    padding: $space-xxlarge 40px !important;
  }
  background-color: $grey-light;

  h2 {
    @include font-oswald-regular;
    font-size: 36px;
    line-height: 42px;
    color: $purple2;
    margin-top: 0;
    margin-bottom: $space-medium;
    text-align: center;
  }

  .subtitle {
    @include font-roboto-light;
    @media (min-width: $medium) {
      width: 80%;
    }
    @media (min-width: $large) {
      width: 50%;
    }
    text-align: center;
  }

  form {
    width: 100%;
  }
  @media (min-width: $medium) {
      form {
        width: 80%;
      }
  }
  @media (min-width: $large) {
    form {
      width: 50%;
    }
  }
}
