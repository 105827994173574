.judges {

}

.judgesWrapper {

}

@media (min-width: $small) and (max-width: $max-small) {
  // 2 per row"
  .judge:nth-child(1) { margin-left: 0; }
  .judge:nth-child(2) { margin-right: 0; }
  .judge:nth-child(3) { margin-left: 0; }
  .judge:nth-child(4) { margin-right: 0; }
  .judge:nth-child(5) { margin-left: 0; }
  .judge:nth-child(6) { margin-right: 0; }
  .judge:nth-child(7) { margin-left: 0; }
  .judge:nth-child(8) { margin-right: 0; }
  .judge:nth-child(9) { margin-left: 0; }
  .judge:nth-child(10) { margin-right: 0; }
  .judge:nth-child(11) { margin-left: 0; }
  .judge:nth-child(12) { margin-right: 0; }
  .judge:nth-child(13) { margin-left: 0; }
  .judge:nth-child(14) { margin-right: 0; }
  .judge:nth-child(15) { margin-left: 0; }
  .judge:nth-child(16) { margin-right: 0; }
  .judge:nth-child(17) { margin-left: 0; }
  .judge:nth-child(18) { margin-right: 0; }
  .judge:nth-child(19) { margin-left: 0; }
  .judge:nth-child(20) { margin-right: 0; }
}

@media (min-width: $medium) {
  // 3 per row
  .judge:nth-child(1) { margin-left: 0; }
  .judge:nth-child(3) { margin-right: 0; }
  .judge:nth-child(4) { margin-left: 0; }
  .judge:nth-child(6) { margin-right: 0; }
  .judge:nth-child(7) { margin-left: 0; }
  .judge:nth-child(9) { margin-right: 0; }
  .judge:nth-child(10) { margin-left: 0; }
  .judge:nth-child(12) { margin-right: 0; }
  .judge:nth-child(13) { margin-left: 0; }
  .judge:nth-child(15) { margin-right: 0; }
  .judge:nth-child(16) { margin-left: 0; }
  .judge:nth-child(18) { margin-right: 0; }
  .judge:nth-child(19) { margin-left: 0; }
  .judge:nth-child(21) { margin-right: 0; }
}
.judge {
  overflow: hidden;
  background-color: $purple;
  background: $gradient-purple-vertical;
  background-size: cover !important;
  background-position: top center;
  color: #fff;
  min-height: 500px !important;
  @media (min-width: $medium) {
    min-height: 500px !important;
  }
  position: relative;
  .overlay {
    transition: opacity 1s ease;
    opacity: 0.5;
    background: $gradient-purple-vertical;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 11;
  }
  .underlay {
    transition: transform 2s ease;
    background-size: cover !important;
    background-position: top center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: absolute;
    z-index: 10;
  }
  h2 {
    text-align: center;
    margin: 0;
    @include font-abril;
    font-size: 18px;
    line-height: 24px;
    font-weight: 300;
  }
  h3 {
    text-align: center;
    margin: 0;
    @include font-oswald-regular;
    font-size: 14px;
    line-height: 18px;
  }
  .judgeTitles {
    z-index: 12;
    transition: transform 0.4s ease 0.2s;
    margin-bottom: 50px;
    @media (min-width: $medium) {
      margin-bottom: 25px;
    }
  }
  button {
    position: absolute;
    bottom: 20px;
    //opacity: 0;
    z-index: 12;
    transition: opacity 0.2s ease;
  }
}

.judge:hover {
  cursor: pointer;
  .overlay {
    opacity: 0;
  }
  .underlay {
    transform: scale(1.1);
  }
  .judgeTitles {
    //transform: translateY(-25px);
    //transition: transform 0.2s ease;
  }
  button {
    //opacity: 1;
    //transition: opacity 0.4s ease 0.2s;
  }
}

// Modal
.popupJudge {
  background: $purple2;
  background: $gradient-purple2-horizontal;
  color: #fff;
  img {
    max-width: 100%;
    height: auto;
  }
  p {
    max-width: 100% !important;
  }
  .popupJudgeBody {
      padding: $space-medium;
  }
  .popupJudgeText {
    @include font-roboto-regular;
    font-size: 14px;
    line-height: 20px;
  }
  h1 {
    margin: 0;
    @include font-abril;
    text-align: center;
  }
  h2 {
    color: $gold;
    margin: 0;
    @include font-oswald-regular;
    text-align: center;
    margin-bottom: $space-medium;
  }
}

//.judgeImage {
//  position: absol
//}
