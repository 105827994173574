@import './variables.scss';
@import './typography.scss';
@import './content.scss';
@import './animations.scss';
@import './components/page.scss';
@import './components/nav.scss';
@import './components/cart.scss';
@import './components/footer.scss';
@import './components/contentWrap.scss';

// Components
@import './components/forms.scss';
@import './components/buttons.scss';
@import './components/categories.scss';
@import './components/countdown.scss';
@import './components/judges.scss';
@import './components/cta.scss';
@import './components/hero.scss';
@import './components/newsletter.scss';
@import './components/inductees.scss';
@import './components/accordion.scss';
@import './components/popup.scss';
@import './components/spacer.scss';
@import './components/modal.scss';
@import './components/cookies.scss';
@import './components/youtube.scss';
@import './components/languageSelector.scss';
//@import './components/aspectRatio.scss';

[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

[style*="--aspect-ratio"] {
  position: relative;
}

[style*="--aspect-ratio"] > :first-child {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

[style*="--aspect-ratio"]::before {
  content: "";
  display: block;
}

@supports not (aspect-ratio: 1/1) {
  [style*="--aspect-ratio"]::before {
    height: 0;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }
}
@supports (aspect-ratio: 1/1) {
  [style*="--aspect-ratio"]::before {
    aspect-ratio: calc(var(--aspect-ratio));
  }
}
