.contentWrapOuter {
    text-align: center;
}

.contentWrapInner {
  text-align: left;
  a {
    color: $purple2;
  }
  padding-left: $space-small;
  padding-right: $space-small;
  @media (min-width: $medium) {
    padding-left: 0;
    padding-right: 0;
  }

}
