#content {
  margin-top: 70px;
  @media (min-height: $large) {
    margin-top: 100px;
  }
}

#page {
    width: 100%;
}
