.inductees {
  padding-top: 0 !important;
  padding-bottom: $space-xlarge !important;
  @media (min-width: $small) {
    padding-bottom: $space-xxlarge !important;
  }
  color: white;
  background: $purple;
  background: $gradient-purple-vertical;
  .header {
    text-align: center;
    margin-bottom: 100px;
    margin-top: $space-xlarge;
    @media (min-width: $small) {
      margin-bottom: 100px;
      margin-top: $space-xxlarge;
    }
    h2 {
      @extend .mediumHeading;
      @extend .halfHeading;
      @include font-abril;
      font-weight: 300;
      margin-top: 0;
    }
    p {
      max-width: 60%;
      margin-left: auto;
      margin-right: auto;
      text-align: center !important;
    }
  }
}

// CTA
.inductee .bannerHeading {
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 20px;
  font-size: 24px;
  line-height: 32px;
}

// Inductee Card
.inductee {
  background: $black-almost;
  position: relative;
  //transition: transform 0.3s ease-out;
  .categoryImage {
    width: 80%;
  }

  .categoryImageFloat {
    width: 40%;
    position: absolute;
    top: $space-small;
    right: $space-small;
  }

  .inducteeImage {
    width: 100%;
    margin-bottom: $space-medium;
  }

  .textWrapper {
    padding-left: $space-medium;
    padding-right: $space-medium;
  }
  .buttonWrapper {
    margin-bottom: $space-medium;
    padding-left: $space-medium;
    padding-right: $space-medium;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  h3 {
    @include font-abril;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    //transition: margin 0.2s ease;
    //margin-bottom: $space-medium + 5; // 30px
    margin-bottom: $space-small; // 10px
    margin-top: 0;
  }

  p {
    @include font-roboto-regular;
    font-size: 14px;
    text-align: center;
  }

  p.shortDescription {
    margin-top: 0;
    //margin-bottom: 0;
    margin-bottom: $space-medium + 5; // 30px
  }

  .inducteeButtonContainer {
    //height:  0;
    //position: absolute;
    //bottom: 20px;
    width: 100%;
    padding: 0 20px;
  }

  button {
    color: #fff;
    background: #591a35;
    background: linear-gradient(90deg, #932240 0%, #591a35 70%, #501933 100%);
    //opacity: 0;
    transition: none;
  }
}

.inductee:hover {
  cursor: pointer;
  //transform: scale(1.05);
  z-index: 11;
  .inducteeImage {
    //margin-top: 0;
  }
  .inducteeButtonContainer {
    height:  auto;
  }
  h3 {
    //margin-top: 10px;
    //margin-bottom: 10px;
  }
  p.shortDescription {
    //margin-bottom: $space-medium + 30px;
  }
  button {
    //opacity: 1;
    //transition: opacity 0.2s ease 0.2s;
  }
}

// Fix inductee margins on cards
@media (min-width: $small) and (max-width: $max-small) {
  .inductee:nth-child(1) { margin-left: 0; }
  .inductee:nth-child(2) { margin-right: 0; }
  .inductee:nth-child(3) { margin-left: 0; }
  .inductee:nth-child(4) { margin-right: 0; }
  .inductee:nth-child(5) { margin-left: 0; }
  .inductee:nth-child(6) { margin-right: 0; }
}

@media (min-width: $medium) {
  .inductee:nth-child(1) { margin-left: 0; }
  .inductee:nth-child(3) { margin-right: 0; }
  .inductee:nth-child(4) { margin-left: 0; }
  .inductee:nth-child(6) { margin-right: 0; }
}

// Last card is a banner
.inducteeBanner {
  .categoryImage {
    width: 80%;
  }
}

// Modal
.popupInductee:focus {
  outline: none;
}
.popupInductee {
  background: white;
  color: #fff;
  .video {
    margin-top: $space-medium;
  }
  .header {
    background: #000;
    //padding: 30px;
    text-align: center;
    //flex-basis: 5px;
    .close {
      color: $gold;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 24px;
    }
    .close:focus {
        outline: none;
    }
    .close:hover {
      color: #fff;
      cursor: pointer;
    }
    h3 {
      text-align: center;
      color: #fff;
      @include font-abril;
      font-size: 36px;
      font-weight: 300;
      line-height: 48px;
      width: 100%;
      max-width: 75%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: $space-medium;
      margin-top: 0;
    }
    .inducteeLabel {
      @include font-oswald-medium;
      text-transform: uppercase;
    }
    .categoryImage {
      max-width: 200px;
      //margin-left: auto;
      //margin-right: auto;
      position: absolute;
      left: 20px;
      top: 20px;
    }
    .inducteeImage {
      margin-bottom: $space-medium;
      margin-top: 0;
      width: 100%;
    }
  }

  p.shortDescription {
    text-align: center;
    @include font-oswald-regular;
    font-size: 18px;
    line-height: 24px;
    margin-top: 0;
    @media (min-width: $medium) {
        font-size: 24px;
        line-height: 32px;
    }
    color: $purple2;
  }
  .longDescription {
    color: black;
    @include font-roboto-regular;
    font-size: 14px;
    line-height: 20px;
    span {
      line-height: 24px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
      max-width: 100%;
    }
    p blockquote, blockquote {
      font-size: 8px;
      margin-left: 0;
      color: #666;
    }
  }
  .specs {
    margin-top: 0px;
    margin-bottom: 20px;
    @media (min-width: $medium) {
        margin-top: 10px;
        margin-bottom: 40px;
    }
  }
  .spec {
    text-align: center;
    h4 {
      margin: 0;
      color: $purple3;
      @include font-oswald-regular;
      font-size: 10px;
      @media (min-width: $medium) {
        font-size: 16px;
      }
    }
    .specValue {
      margin: 0;
      color: $purple2;
      @include font-oswald-medium;
      font-size: 16px;
      @media (min-width: $medium) {
        font-size: 22px;
      }
    }
  }

  .body {
    padding: 30px;
  }

  .footer {
    background: $purple2;
    background: $gradient-purple-horizontal;
  }
}
