.normalText {
  padding-left: 0;
  padding-right: 0;
  @media (min-width: $small) {
    padding-left: $space-medium;
    padding-right: $space-medium;
  }
  @media (min-width: $large) {
    padding-left: 0;
    padding-right: 0;
  }
}

.grid {
  .normalText {
    padding-left: 0;
    padding-right: 0;
  }
}


// Big text used as callouts
.bigText {
  @include font-oswald-medium;
  font-family: $font-family-oswald !important;
  //width: 90%;
  @media (min-width: $medium) {
    //width: 70%;
  }
  margin: 0 auto;
  padding-left: 0;
  padding-right: 0;
  @media (min-width: $small) {
    padding-left: $space-medium;
    padding-right: $space-medium;
  }
  @media (min-width: $large) {
    padding-left: 0;
    padding-right: 0;
  }
  p {
    //font-family: $font-family-oswald !important;
    @include font-oswald-regular;
    font-size: 24px;
    margin-top: 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
  }
}

// Small text
.smallText {
  font-size: 14px;
}

// Tiny text
.tinyText {
  font-size: 11px;
}

// Headings
.largeHeading {
  font-size: 60px;
  line-height: 72px;
}
.mediumHeading {
  $mediumHeadingSizeBase: 48px;
  font-size: $mediumHeadingSizeBase / 1.5;
  line-height: ($mediumHeadingSizeBase * 1.4) / 1.5;
  @media (min-width: $medium) {
    font-size: $mediumHeadingSizeBase;
    line-height: #{$mediumHeadingSizeBase * 1.4};
  }
}
.smallHeading {
  $mediumHeadingSizeBase: 32px;
  font-size: $mediumHeadingSizeBase / 1.5;
  line-height: ($mediumHeadingSizeBase * 1.4) / 1.5;
  @media (min-width: $medium) {
    font-size: $mediumHeadingSizeBase;
    line-height: #{$mediumHeadingSizeBase * 1.4};
  }
}

// Half page width on large pages
.halfHeading {
  width: 100%;
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  @media (min-width: $medium) {
    max-width: 50%;
  }
}


// Buttons
button {
  text-transform: uppercase;
}
button:active {
  outline: none;
  box-shadow: none !important;
}
button:hover {
  outline: none;
  box-shadow: none !important;
}
