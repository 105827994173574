.react-cookie-law-dialog  {
  top: auto !important;
  bottom: 0 !important;
  transition: 2s;
  opacity: 1;
  animation: slideUp 2s cubic-bezier(0.07, 0.4, 0.185, 1) forwards;
  transform: translate3d(0, 8vh, 0);
  background-color: $purple !important;
  font-family: $font-family-roboto !important;
  font-weight: 400 !important;
  a {
    color: $gold !important;
  }

  button {
    background: $gold !important;
    color: $purple2 !important;
    border: none !important;
    padding: 10px 20px !important;
    border-radius: 20px;
  }

  label {
    vertical-align: sub !important;
    padding-left: 25px;
  }
}
.react-cookie-law-msg {
    color: #fff !important;
    font-family: $font-family-roboto !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    text-align: center;
}
