// Breakpoints
$small: 576px;
$medium: 768px;
$large: 992px;
$xlarge: 1200px;
$xxlarge: 1400px;

// Vertical Breakpoints
$vertical-small: 400px;
$vertical-medium: 600px;
$vertical-large: 800px;

$max-small: $medium - 1;
$max-medium: $large - 1;
$max-large: $xlarge - 1;
$max-xlarge: $xxlarge - 1;

// Colours
$blueDark: #140d40; // MPB
$blue: #090a66; // MPB
$purple: #501933; // MPB
$purple2: #140d40;
$purple3: #932240; // MPB
$gold: #ce8e47;
$yellow: #f8d44c;
$tan: #c3ae8f;
$peach: #ffd394;
//$yellow:
$grey-light: #F4F4F4;
$black-almost: #010101;

// Colours applied
$nav-link: #FFF;
$nav-link-hover: $gold;

// Gradients
$gradient-purple-horizontal: linear-gradient(90deg, #140D40 0%, #331339 50%, #501933 100%);
$gradient-purple2-horizontal: linear-gradient(90deg, #932240 0%, #501933 100%);
$gradient-purple-vertical: linear-gradient(180deg, #140D40 0%, #331339 50%, #501933 100%);
$gradient-gold-horizontal: linear-gradient(90deg, $gold 0%, $yellow 100%);
$gradient-peach-horizontal: linear-gradient(90deg, $tan 0%, $peach 100%);


// Spacing
$space-small: 15px;
$space-medium: 30px;
$space-large: 60px;
$space-xlarge: 100px;
$space-xxlarge: 200px;
$space-xxxlarge: 300px;

// Fonts
$font-family-roboto: 'Roboto', sans-serif;
$font-family-oswald: 'Oswald', sans-serif;
$font-family-abril: 'Abril Fatface', cursive;

@mixin font-roboto-light {
  font-family: $font-family-roboto;
  font-weight: 300;
}

@mixin font-roboto-regular {
  font-family: $font-family-roboto;
  font-weight: 400;
}

@mixin font-roboto-bold {
  font-family: $font-family-roboto;
  font-weight: 700;
}


@mixin font-abril {
  font-family: $font-family-abril;
  font-weight: 300;
}

@mixin font-oswald-medium {
  font-family: $font-family-oswald;
  font-weight: 500;
}

@mixin font-oswald-regular {
  font-family: $font-family-oswald;
  font-weight: 400;
}

@mixin font-oswald-light {
  font-family: $font-family-oswald;
  font-weight: 300;
}

// Buttons
$button-min-width: 300px;
$button-max-width: 500px;
