#nominees {
  #categories_header {
    //margin-bottom: 86px;
  }
  //margin-bottom: 150px;
}

.categoriesContainer {
  flex-direction: column;
  align-items: flex-start;
  @media (min-width: $small) {

    display: flex;
    //flex-wrap: wrap;
    //flex-direction: row !important;
  }
  @media (min-width: $medium) {
    display: flex;
    flex-direction: row !important;
  }
}

.categoriesContainerCountdown {

}

.categoriesCountdownLink {
  text-align: center;
  a {
    max-width: 300px !important;
    margin: 0 auto;
    margin-top: $space-medium + 10px;
  }
}

.categoriesBackgroundContainer {
  padding-top: $space-medium;
  @media (min-width: $medium) {
    padding-top: 86px;
  }
  padding-bottom: 100px;
  background: $purple2;
  background: $gradient-purple-vertical;
}

.categoriesBackgroundContainerCountdown {
  // showing countdown (no flex)
  text-align: center;
  display: block;
  width: 100%;
  color: #fff;
  padding-top: $space-xlarge;
  padding-bottom: $space-xlarge;
  @media (min-width: $small) {
    padding-top: $space-xxlarge;
    padding-bottom: $space-xxlarge;
  }
}

.category {
  background: $purple;
  @media (max-width: $max-small) {
    margin-bottom: $space-medium;
    margin-left: $space-medium;
    margin-right: $space-medium;
  }
  @media (min-width: $medium) {
    margin-right: 43px;
  }
  // Add some padding for larger screen to keep portrait shape
  @media (min-width: $xlarge) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media (min-width: $xxlarge) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  position: relative;
  overflow: visible;
  .categoryImageContainer {
    text-align: center;
  }
  .categoryImage {
    margin: 0 auto;
    width: 50%;
    @media (min-width: $medium) {
      width: 100%
    }
    transition: transform 0.4s ease;
  }
  .categoryContent {
    position: relative;
    z-index: 12;
    overflow: visible;
    padding: 10px !important;
    @media (min-width: $medium) {
        padding: 10px !important;
    }
    @media (min-width: $large) {
        padding: 24px !important;
    }
  }
  .categoryContent:focus {
    outline: none;
  }

  // Corner indicators
  .voted {
    display: none;
    background: $gold;
    background: $gradient-gold-horizontal;
    position: absolute;
    left: -25px;
    top: 12px;
    text-align: center;
    padding: 2px 30px;
    transform: rotate(-45deg);
    @include font-oswald-medium;
    text-transform: uppercase;
  }

  // Background stuff
  .backgroundOverlay {
    background: $purple;
    background: $gradient-purple-horizontal;
    transition: transform 0.4s ease;
    z-index: 12;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .backgroundOverlayInner {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform 3s ease;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: cover;
    opacity: 0.2;
  }
  // Fix for Cl(ass)ic background faux pas
  .classBackgroundFix {
    background-position-x: left;
  }
}

// Hover animations
$categoryImageHoverSmall: scale(1.2);
$categoryImageHoverMedium: scale(1.8);
$categoryImageHoverLarge: scale(1.6);
$categoryImageHoverXLarge: scale(1.4);
.category:hover, .categorySelected {
  filter: drop-shadow(15px 15px 15px rgba(0,0,0,0.5));
  .categoryImage {
    transform: $categoryImageHoverSmall;
    @media (min-width: $medium) {
      transform: $categoryImageHoverMedium;
    }
    @media (min-width: $large) {
      transform: $categoryImageHoverLarge;
    }
    @media (min-width: $xlarge) {
      transform: $categoryImageHoverXLarge;
    }
  }
  .backgroundOverlay {
    @media (min-width: $medium) {
      transform: scale(1.2);
    }
    @media (min-width: $xlarge) {
      transform: scale(1.1);
    }
  }
  .backgroundOverlayInner {
    transform: scale(1.2);
  }
}

.categoriesContainer:hover {
  // We don't want the selected category to be scaled when hovering over other categories
  .categorySelected {
    filter: unset;
    .categoryImage {
      transform: none;
    }
    .backgroundOverlay {
      transform: none;
    }
    .backgroundOverlayInner {
      transform: none;
    }
  }

  // Re-apply hover to selected
  .category:hover {
    .categoryImage {
      transform: scale(1.2);
      @media (min-width: $medium) {
        transform: scale(1.8);
      }
      @media (min-width: $xlarge) {
        transform: scale(1.3);
      }
    }
    .backgroundOverlay {
      @media (min-width: $medium) {
        transform: scale(1.2);
      }
      @media (min-width: $xlarge) {
        transform: scale(1.1);
      }
    }
    .backgroundOverlayInner {
      transform: scale(1.2);
    }
  }
}

// Fix margins on 1,3,4,6
.category:first-child {
  @media (min-width: $medium) {
    margin-left: 48px;
  }
}
.category:nth-child(even) {
  .backgroundOverlay {
    background: $purple2;
    background: $gradient-purple2-horizontal;
  }
}

// Selected category stuff
.showButtonContainer {
  button {
    width: 100%;
    padding: 10px 10px !important;
    @media (min-width: $large) {
      padding: 10px 20px !important;
    }
  }
  position:relative;
  text-align: center;
  .showButtonIndicator {
    display: none;
    position: absolute;
    margin: 0 auto;
    left: calc(50% - 10px);
    //background: $gold;
    //width: 10px;
    //height: 10px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #e3b04b;
  }
}
.categoryVoteSelected {
  .voted {
    display: block;
  }
}

.categorySelected {
  .showButtonIndicator {
    display: block;
  }
}

.nominees {
  //background: red;
}

#productsAnchor {
  margin-bottom: 50px;
}

#nomineesContainer {
  display: grid;
  padding: $space-medium;
  grid-template-columns: repeat(1, 1fr);
  //grid-template-rows: 1fr;
  @media (min-width: $small) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: $medium) {
    grid-template-columns: repeat(3, 1fr);
  }
  gap: $space-medium;
}

@media (min-width: $small) and (max-width: $max-small) {
  .nominee:nth-child(1) { margin-left: 0; }
  .nominee:nth-child(2) { margin-right: 0; }
  .nominee:nth-child(3) { margin-left: 0; }
  .nominee:nth-child(4) { margin-right: 0; }
  .nominee:nth-child(5) { margin-left: 0; }
  .nominee:nth-child(6) { margin-right: 0; }
  .nominee:nth-child(7) { margin-left: 0; }
  .nominee:nth-child(8) { margin-right: 0; }
  .nominee:nth-child(9) { margin-left: 0; }
  .nominee:nth-child(10) { margin-right: 0; }
  .nominee:nth-child(11) { margin-left: 0; }
  .nominee:nth-child(12) { margin-right: 0; }
  .nominee:nth-child(13) { margin-left: 0; }
  .nominee:nth-child(14) { margin-right: 0; }
  .nominee:nth-child(15) { margin-left: 0; }
  .nominee:nth-child(16) { margin-right: 0; }
  .nominee:nth-child(17) { margin-left: 0; }
  .nominee:nth-child(18) { margin-right: 0; }
  .nominee:nth-child(19) { margin-left: 0; }
  .nominee:nth-child(20) { margin-right: 0; }
}

@media (min-width: $medium) {
  .nominee:nth-child(1) { margin-left: 0; }
  .nominee:nth-child(3) { margin-right: 0; }
  .nominee:nth-child(4) { margin-left: 0; }
  .nominee:nth-child(6) { margin-right: 0; }
  .nominee:nth-child(7) { margin-left: 0; }
  .nominee:nth-child(9) { margin-right: 0; }
  .nominee:nth-child(10) { margin-left: 0; }
  .nominee:nth-child(12) { margin-right: 0; }
  .nominee:nth-child(13) { margin-left: 0; }
  .nominee:nth-child(15) { margin-right: 0; }
  .nominee:nth-child(16) { margin-left: 0; }
  .nominee:nth-child(18) { margin-right: 0; }
  .nominee:nth-child(19) { margin-left: 0; }
  .nominee:nth-child(21) { margin-right: 0; }
}
.nominee {
  //display: block !important;
  background: $black-almost;
  color: #fff;
  position: relative;
  overflow: hidden;
  text-align: center;
  .nomineeImage {
    //margin-top: 20px;
    width: 100%;
    margin-bottom: $space-medium;
  }

  // Corner indicators
  .voted {
    color: $purple;
    background: $gold;
    background: $gradient-gold-horizontal;
    position: absolute;
    left: -25px;
    top: 12px;
    text-align: center;
    padding: 2px 30px;
    transform: rotate(-45deg);
    @include font-oswald-medium;
    text-transform: uppercase;
  }

  .textWrapper {
    padding-left: $space-medium;
    padding-right: $space-medium;
  }
  .buttonWrapper {
    padding-bottom: $space-medium;
    padding-left: $space-medium;
    padding-right: $space-medium;
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
  }

  h3 {
    @include font-abril;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    transition: margin 0.2s ease;
    //margin-bottom: $space-medium + 5; // 30px
    margin-bottom: $space-small; // 10px
    margin-top: 0;
    //margin-top: $space-medium + 5; // 30px
  }

  p {
    @include font-roboto-regular;
    font-size: 14px;
    text-align: center;
  }

  p.shortDescription {
    margin-top: 0;
    //margin-bottom: 0;
    margin-bottom: $space-medium + 5; // 30px
    transition: margin 0.2s ease;
  }

  .nomineeButtonContainer {
    //height:  0;
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding: 0 20px;
  }

  /**
  button {
    color: #fff;
    background: #591a35;
    background: linear-gradient(90deg, #932240 0%, #591a35 70%, #501933 100%);
    opacity: 1;
    transition: none;
  }
  **/

  .detailsButton {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    margin-bottom: $space-small;
  }
}

.nomineeSelected {
  //border: 2px solid $gold;
  transition: box-shadow 0.3s;
  box-shadow: 0 0 0px 2px $gold;
}

.nominee:hover {
  cursor: pointer;
  .nomineeImage {
    //margin-top: 0;
  }
  .nomineeButtonContainer {
    //height:  auto;
  }
  h3 {
    //margin-top: 10px;
    //margin-bottom: 10px;
  }
  p.shortDescription {
    //margin-bottom: $space-medium + 30px;
  }
  button {
    opacity: 1;
    //transition: opacity 0.2s ease 0.2s;
  }
}
