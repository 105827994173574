.cta {
  color: #fff;
  padding: $space-xlarge 0px !important;
  @media (min-width: $small) {
    padding: $space-xxlarge 0px !important;
  }
  background-color: $purple !important;
  position: relative;
  flex-basis: 500px;
  .overlay {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: $purple2;
    opacity: 0.5;
    z-index: 11;
  }
  .aboveOverlay {
    z-index: 12;
    text-align: center;
  }
  h2 {
    @extend .mediumHeading;
    @include font-abril;
    text-align: center;
    margin: $space-medium auto $space-medium auto;
  }
  h2.smaller {
    @extend .smallHeading;
    @include font-abril;
    text-align: center;
    margin: $space-medium auto $space-medium auto;
    @media (min-width: $medium) {
      width: 80%;
    }
    @media (min-width: $large) {
      width: 50%;
    }
    padding-left: $space-medium;
    padding-right: $space-medium;
    @media (min-width: $large) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  h3 {
    @extend .bigText;
    font-size: 24px;
    line-height: 36px;
    //width: 100%;
    margin: 0 auto $space-medium*2 auto;
    @media (min-width: $medium) {
      width: 80%;
    }
    @media (min-width: $large) {
      width: 50%;
    }
  }
  button {
    min-width: $button-min-width;
  }
}
