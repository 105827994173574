.LanguageSelector {
  display: inline;

  .LanguageSelectorButton {
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    //margin-bottom: 10px;
    font-size: 14px;
    line-height: 12px;
    border-radius: 18px;
    padding: 10px 20px;
    @media (max-width: $max-small) {
      padding: 10px 13px !important;
    }
    text-transform: uppercase;
    font-family: 'Oswald',sans-serif;
    font-weight: 500;

    span {
      vertical-align: inherit !important;
    }
  }
}

.LanguageSelectorPopup {
    position: relative;
    z-index: 15;
    padding: 5px;
    border-radius: 5px;
    a {
      text-decoration: none;
    }
    button {
      border: none;
      padding: 0;
      margin-bottom: 10px;
      display: block;
      width: 100%;
      text-align: left;
      span {
        vertical-align: top;
        margin-right: 5px;
      }
    }
    button:hover {
      cursor: pointer;
      //font-weight: bold;
      color: $purple2;
    }
}
