.accordion {

}

.accordionPanel {
  color: #fff;
  background: #591a35;
  background: linear-gradient(90deg, #932240 0%, #591a35 70%, #501933 100%);
  //margin-bottom: 20px;

  h4 {
    @include font-oswald-medium;
    margin: 10px 20px;
  }

  polyline {
    fill: #fff !important;
    stroke: #fff !important;
  }
}
